import {CMS_Path} from "@/src/core/app/domain/cms/@types/common/CMS_Path";
import AppController from "@/src/ui/controllers/AppController";
import pageHeader from "@/src/ui/components/generic/PageHeader";

const UrlUtils = {
  // this function will guarantee that every url build by front-end is generated with the same pattern
  // i.e. with last slash or not
  buildAppUrl: (path: string) : string => {
    if (path === '/') {
      return path;
    }
    if (path.endsWith('/')) {
      return path.substring(0, path.length - 1);
    }
    return path;
  },

  getHomepageUrl: (langcode: string) : string => {
    return UrlUtils.buildAppUrlWithLangPrefix(langcode, '/');
  },

  buildAppUrlWithLangPrefix: (langcode: string, path: string) : string => {
    const url = UrlUtils.buildAppUrl(path);
    return `/${langcode}${url}`
  },

  buildPublicUrl: (path: string) : string => {
    return `${process.env.NEXT_PUBLIC_PUBLIC_URL}${UrlUtils.getCmsLinkUrl(path)}`;
  },

  buildPublicUrlWithFallback: (path: string | null, fallback: string) : string => {
    if (path === null) {
      return UrlUtils.buildPublicUrl(fallback);
    }
    return UrlUtils.buildPublicUrl(UrlUtils.getCmsLinkUrl(path));
  },

  getCmsLinkUrl: (url: string) : string => {
    if (url.indexOf('/sites/default/files') !== -1) {
      return url;
    }
    if (url.startsWith('/cms')) {
      return url.substring('/cms'.length);
    }
    return url;
  },

  getCmsLinkUrlFromPath: (path: CMS_Path) : string => {
    return `/${path.langcode}${path.alias}`;
  },

  addParamsToUrl: (url: string, params?: Record<string, any>) : string => {
    if (!params) {
      return  url;
    }

    const urlQueryParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      urlQueryParams.set(key, params[key] as string);
    });
    if ('page' in params) {
      if (!params.page) {
        urlQueryParams.delete('page');
      }
      else {
        const page = parseInt(params.page);
        if (page === 0) {
          urlQueryParams.delete('page');
        }
      }
    }

    const sUrlQueryParams = urlQueryParams.toString();
    if (sUrlQueryParams === '') {
      return url;
    }
    return `${url}?${sUrlQueryParams}`;
  }
}

export default UrlUtils;
