import cn from "classnames";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {Fragment} from "react";
import {useTranslation} from "next-i18next";

interface _Props {
  title?: string
  message: string
  size?: "small" | "medium" | "large"
  type?: T_NotificationType
}

const icons : Record<T_NotificationType, string> = {
  [T_NotificationType.SUCCESS]: '/assets/icons/check-circle.svg',
  [T_NotificationType.INFO]: '/assets/icons/info.svg',
  [T_NotificationType.WARNING]: '/assets/icons/error.svg',
  [T_NotificationType.ERROR]: '/assets/icons/error.svg',
};

const NotificationMessage = (props: _Props) => {
  const {t} = useTranslation();
  const size : string = props.size || 'small';
  let type = props.type || T_NotificationType.ERROR;

  let lines = (t(props.message as any) as string)
    .split('\n')
    .map(item => item.trim())
    .filter(item => item.length != 0)
  ;

  return (
    <div className="py-3.75 sm:py-5">
      <div className={cn(
        {
          'bg-yellow-notification': type === T_NotificationType.INFO,
          'bg-green-notification': type === T_NotificationType.SUCCESS,
          'bg-red-notification': type === T_NotificationType.ERROR || type === T_NotificationType.WARNING,
          'text-red': type === T_NotificationType.ERROR || type === T_NotificationType.WARNING,
        },
        {
          'text-sm': size === 'small',
          'text-base': size === 'medium',
          'text-lg': size === 'large',
        }
      )}>
        {props.title && (
          <p className='p-3.5 pb-0 uppercase font-bold'>{t(props.title as any)}</p>
        )}
        <article className="flex items-top space-x-3.5 p-3.5">
          <div className="flex-none w-5 h-full">
            <img className="w-full h-auto" src={icons[type]} alt="" />
          </div>
          <p className="flex-1">
            {lines.map((line, index) => (
              <Fragment key={index}>
                {line}
                {index !== lines.length -1 && (<br />)}
              </Fragment>
            ))}
          </p>
        </article>
      </div>
    </div>
  );
}

export default NotificationMessage;
