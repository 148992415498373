import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import SentryUtils from "@/src/common/utils/sentry";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import UserLogoutUtils from "@/src/core/app/utils/user-logout";
import {AnchorProps} from "@/src/ui/components/generic/Anchor/types";
import NavigationUtils from "@/src/core/app/utils/navigation";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";

const AnchorLogout = (props: AnchorProps) => {
  const appInfo = useAppInfo();
  const modalState = useModalProvider(state => state);

  const onClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    modalState.toggleModal(ModalKey.LOADER);

    const response = await UserLogoutUtils.logout();
    if (!response.isOk) {
      SentryUtils.captureMessage('User logout KO');
      modalState.toggleModal(ModalKey.NOTIFICATION, FormErrorUtils.getUnexpectedError().notification);
    }

    NavigationUtils.goToHome(appInfo.langcode);
  };

  return (
    <a onClick={onClick} {...props}>
      {props.children}
    </a>
  )
};

export default AnchorLogout;
