import {NetworkResponseError, NetworkResponseErrorType} from "@/src/common/network/NetworkResponse";
import {FormErrorRecord} from "@/src/core/app/domain/forms/@types/FormErrorRecord";
import {FormErrorProps} from "@/src/core/app/domain/forms/@types/FormErrorProps";
import {FormErrorLogReason} from "@/src/core/app/domain/forms/@types/FormErrorLogReason";
import SentryUtils from "@/src/common/utils/sentry";
import NetworkNotificationUtils from "@/src/core/app/utils/network-notification";
import NetworkUtils from "@/src/common/network/NetworkUtils";

const FormErrorUtils = {
  getUnexpectedError: () : FormErrorRecord => ({
    notification: NetworkNotificationUtils.getErrorServerUnexpectedNotification(),
  }),
  analyzeNetworkError: (props: FormErrorProps) : FormErrorRecord | null => {
    if (props.networkError.type === NetworkResponseErrorType.CLIENT) {
      return {
        notification: NetworkNotificationUtils.getErrorClientNotification(),
      }
    }
    if (props.networkError.type === NetworkResponseErrorType.SERVER_UNEXPECTED) {
      FormErrorUtils.callLogFn(FormErrorLogReason.NETWORK_ERROR_UNEXPECTED, props);
      return FormErrorUtils.getUnexpectedError();
    }

    return null;
  },
  FormErrorMapKeyGetter__DrupalCustomAPI: (networkError: NetworkResponseError) => {
    return networkError.data['error'];
  },
  callLogFn: (reason: FormErrorLogReason, props: FormErrorProps) => {
    if (props.logFn === undefined) {
      return;
    }
    props.logFn(reason);
  },
  getErrorInMap: (props: FormErrorProps) : FormErrorRecord => {
    const mappingKey = props.formErrorMapKeyGetter ?
      props.formErrorMapKeyGetter(props.networkError) :
      NetworkUtils.NetworkErrorKeyGetter__DrupalCustomAPI(props.networkError)
    ;
    if (mappingKey === undefined) {
      FormErrorUtils.callLogFn(FormErrorLogReason.KEY_NOT_MAPPED, props);
      return FormErrorUtils.getUnexpectedError();
    }
    const errorData = props.formErrorMap[mappingKey];
    if (errorData === undefined) {
      FormErrorUtils.callLogFn(FormErrorLogReason.ERROR_KEY_NOT_IN_MAP, props);
      return FormErrorUtils.getUnexpectedError();
    }

    return errorData;
  },
  getError: <T = any>(props: FormErrorProps<T>) : FormErrorRecord<T> => {
    let formError = FormErrorUtils.analyzeNetworkError(props);
    if (formError) {
      return formError;
    }
    return FormErrorUtils.getErrorInMap(props);
  },
  logNetworkError: (
    message: string,
    reason: FormErrorLogReason,
    networkError: NetworkResponseError
  ) => {
    if (reason === FormErrorLogReason.NETWORK_ERROR_UNEXPECTED) {
      SentryUtils.logNetworkError(message, networkError);
      return;
    }

    if (reason === FormErrorLogReason.ERROR_KEY_NOT_IN_MAP) {
      SentryUtils.logNetworkError(`${message} - ${reason}`, networkError);
      return;
    }
  }
};

export default FormErrorUtils;
