import {CMS_FileImageWithMeta} from "@/src/core/app/domain/cms/@types/file/CMS_FileImageWithMeta";
import {ImgAttrs} from "@/src/ui/@types/media/ImgAttrs";
import {T_MediaImage} from "@/src/core/app/domain/models/media/T_MediaImage";
import {generateUUID} from "@/src/common/utils/uuid";

const UiMediaUtils = {
  getImgPropsFromFileImageWithMeta: (fileImage: CMS_FileImageWithMeta | null) : ImgAttrs => {
    if (fileImage === null) {
      return {};
    }

    return {
      alt: fileImage.meta.alt || "",
      title: fileImage.meta.title || "",
      src: fileImage.uri.url,
    };
  },
  getImgPropsFromMediaImage: (mediaImage: T_MediaImage | null) : ImgAttrs => {
    if (!mediaImage) {
      return {};
    }

    return {
      alt: mediaImage.meta.alt || "",
      title: mediaImage.meta.title || "",
      src: mediaImage.field_media_image.uri.url,
    };
  },

  generateAssetMediaImage: (asset: string) : T_MediaImage => {
    return {
      id: generateUUID(),
      type: 'file--file',
      langcode: 'es',
      meta: {
        alt: null,
        title: null,
        width: null,
        height: null,
      },
      field_media_image: {
        id: generateUUID(),
        langcode: 'es',
        status: true,
        filename: '',
        filemime: '',
        filesize: 0,
        uri: {
          value: '',
          url: asset,
        },
        image_style_uri: {}
      }
    }
  },
};

export default UiMediaUtils;
