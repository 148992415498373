import React, {PropsWithChildren, useState} from "react";
import type {RouteProps} from "@/src/ui/@types/RouteProps";

export type RouteContextProviderProps = {
  routeProps: RouteProps
};

export const RouteContext = React.createContext<RouteProps>({} as RouteProps);

export const RouteContextProvider = (props: PropsWithChildren<RouteContextProviderProps>) => {
  const [state, ] = useState({
    ...props.routeProps,
  });

  return (
    <RouteContext.Provider value={state}>
      {props.children}
    </RouteContext.Provider>
  );
}
