import { ReactNode } from "react";
import cn from "classnames";

interface MaxWidthWrapperProps {
  children: ReactNode
  className?: string
}

const MaxWidthWrapper = (props: MaxWidthWrapperProps): JSX.Element => (
  <div className={cn("max-w-295 h-full mx-auto", props.className)}>
    {props.children}
  </div>
);

export default MaxWidthWrapper;
