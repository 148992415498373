export enum T_NotificationType {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface T_Notification {
  type: T_NotificationType
  title: string
  message: string
  onClose?: VoidFunction
}