import type { IEnvVars } from "../@types/IEnvVars";
import { injectable } from "inversify";

@injectable()
export class EnvVars implements IEnvVars {
  // Cms
  cmsBaseUrl: string = process.env.CMS_BASE_URL || "";
  cmsApiUrl: string = process.env.CMS_API_URL || "";
  cmsApiTimeout: number = process.env.CMS_API_TIMEOUT ?
    parseInt(process.env.CMS_API_TIMEOUT) :
    10000
  ;
  cmsApiKey: string = process.env.CMS_API_KEY || "";
  cmsAssetUrl: string = process.env.CMS_ASSET_URL || "";

  // Network config
  publicNetworkTimeout: number = process.env.NEXT_PUBLIC_NETWORK_TIMEOUT ?
    parseInt(process.env.NEXT_PUBLIC_NETWORK_TIMEOUT) :
    5000
  ;

  // Ssr
  publicSsrUrl: string = process.env.NEXT_PUBLIC_SSR_URL || "";
  publicSsrTimeout: number = process.env.NEXT_PUBLIC_SSR_TIMEOUT ?
    parseInt(process.env.NEXT_PUBLIC_SSR_TIMEOUT) :
    5000
  ;

  // Booking
  publicBookingApiUrl: string = process.env.NEXT_PUBLIC_BOOKING_API_URL || "";
  publicBookingApiTimeout: number = process.env.NEXT_PUBLIC_BOOKING_API_TIMEOUT ?
    parseInt(process.env.NEXT_PUBLIC_BOOKING_API_TIMEOUT) :
    20000
  ;

  // User
  publicUserApiUrl: string = process.env.NEXT_PUBLIC_USER_API_URL || "";
  publicUserApiTimeout: number = process.env.NEXT_PUBLIC_USER_API_TIMEOUT ?
    parseInt(process.env.NEXT_PUBLIC_USER_API_TIMEOUT) :
    5000
  ;

  // Currency
  publicCurrencyApiUrl: string = process.env.NEXT_PUBLIC_CURRENCY_API_URL || "";
  publicCurrencyApiTimeout: number = process.env.NEXT_PUBLIC_CURRENCY_API_TIMEOUT ?
    parseInt(process.env.NEXT_PUBLIC_CURRENCY_API_TIMEOUT) :
    5000
  ;

  get isProduction() {
    return process.env.NODE_ENV === "production";
  }
}
