import {T_Notification, T_NotificationType} from "@/src/ui/@types/T_Notification";
import {NetworkResponseError, NetworkResponseErrorType} from "@/src/common/network/NetworkResponse";
import {NetworkErrorNotificationProps} from "@/src/core/app/domain/network/NetworkErrorNotificationProps";
import NetworkUtils from "@/src/common/network/NetworkUtils";
import {NetworkErrorNotificationLogReason} from "@/src/core/app/domain/network/NetworkErrorNotificationLogReason";
import SentryUtils from "@/src/common/utils/sentry";

const NetworkNotificationUtils = {
  getErrorServerUnexpectedNotification: () : T_Notification => ({
    type: T_NotificationType.ERROR,
    title: 'network_notification_utils.error_server_unexpected.notification_title',
    message: 'network_notification_utils.error_server_unexpected.notification_message'
  }),

  getErrorClientNotification: () : T_Notification => ({
    type: T_NotificationType.ERROR,
    title: 'network_notification_utils.error_client.notification_title',
    message: 'network_notification_utils.error_client.notification_message'
  }),

  callLogFn: (reason: NetworkErrorNotificationLogReason, props: NetworkErrorNotificationProps) => {
    if (props.logFn === undefined) {
      return;
    }
    props.logFn(reason);
  },

  getNotificationInMap: (props: NetworkErrorNotificationProps) : T_Notification => {
    const mappingKey = props.mapKeyGetter ?
      props.mapKeyGetter(props.networkError) :
      NetworkUtils.NetworkErrorKeyGetter__DrupalCustomAPI(props.networkError)
    ;
    const notification = props.map[mappingKey];
    if (notification === undefined) {
      NetworkNotificationUtils.callLogFn(NetworkErrorNotificationLogReason.ERROR_KEY_NOT_IN_MAP, props);
      return NetworkNotificationUtils.getErrorServerUnexpectedNotification();
    }

    return notification;
  },

  analyzeNetworkError: (props: NetworkErrorNotificationProps) : T_Notification | null => {
    if (props.networkError.type === NetworkResponseErrorType.CLIENT) {
      return NetworkNotificationUtils.getErrorClientNotification();
    }
    if (props.networkError.type === NetworkResponseErrorType.SERVER_UNEXPECTED) {
      NetworkNotificationUtils.callLogFn(NetworkErrorNotificationLogReason.NETWORK_ERROR_UNEXPECTED, props);
      return NetworkNotificationUtils.getErrorServerUnexpectedNotification();
    }

    return null;
  },

  getNetworkErrorNotification: (props: NetworkErrorNotificationProps) : T_Notification => {
    let notification = NetworkNotificationUtils.analyzeNetworkError(props);
    if (notification) {
      return notification;
    }
    return NetworkNotificationUtils.getNotificationInMap(props);
  },

  logNetworkError: (
    message: string,
    reason: NetworkErrorNotificationLogReason,
    networkError: NetworkResponseError
  ) => {
    if (reason === NetworkErrorNotificationLogReason.NETWORK_ERROR_UNEXPECTED) {
      SentryUtils.logNetworkError(message, networkError);
      return;
    }

    if (reason === NetworkErrorNotificationLogReason.ERROR_KEY_NOT_IN_MAP) {
      SentryUtils.logNetworkError(`${message} - ${reason}`, networkError);
      return;
    }
  }
}

export default NetworkNotificationUtils;
