import type { AxiosInstance, AxiosResponse } from "axios";
import axios, {AxiosRequestHeaders} from "axios";
import {locator} from "@/src/core/app/ioc";
import {EnvVars} from "@/src/core/app/domain/models/EnvVars";
import {TYPES} from "@/src/core/app/ioc/types";

export interface GetRequestOptions {
  params?: Record<string, unknown>
  paramsSerializer?: (params: Record<string, unknown>) => string
  headers?: AxiosRequestHeaders
}

export interface PostRequestOptions<D = Record<string, unknown>> {
  data?: D
  params?: Record<string, unknown>
  paramsSerializer?: (params: Record<string, unknown>) => string
  headers?: AxiosRequestHeaders
}

export type PutRequestOptions<D> = PostRequestOptions<D>;
export type PatchRequestOptions<D> = PostRequestOptions<D>;
export type DeleteRequestOptions = GetRequestOptions;

export class RestClient {
  private client: AxiosInstance;

  constructor(baseUrl: string, options: Record<string, any> = {}) {
    const env = locator.get<EnvVars>(TYPES.IEnvVars);
    this.client = axios.create({
      baseURL: baseUrl,
      timeout: env.publicNetworkTimeout,
      ...options,
    });
  }

  async get<T>(url: string, options?: GetRequestOptions): Promise<AxiosResponse<T>> {
    return await this.client.get<T>(url, options);
  }

  async delete<T>(url: string, options?: DeleteRequestOptions): Promise<AxiosResponse<T>> {
    return await this.client.delete<T>(url, options);
  }

  async post<T, D>(url: string, options?: PostRequestOptions<D>): Promise<AxiosResponse<T>> {
    const {
      data,
      ...rest
    } = options || {};
    return await this.client.post<T>(url, data, rest);
  }

  async put<T, D>(url: string, options?: PutRequestOptions<D>): Promise<AxiosResponse<T>> {
    const {
      data,
      ...rest
    } = options || {};
    return await this.client.put<T>(url, data, rest);
  }

  async patch<T, D>(url: string, options?: PatchRequestOptions<D>): Promise<AxiosResponse<T>> {
    const {
      data,
      ...rest
    } = options || {};
    return await this.client.patch<T>(url, data, rest);
  }
}
