import cn from "classnames";
import {CMS_FileImageWithMeta} from "@/src/core/app/domain/cms/@types/file/CMS_FileImageWithMeta";
import UiMediaUtils from "@/src/ui/utils/media";
import {ImgAttrs} from "@/src/ui/@types/media/ImgAttrs";

interface BgImgProps {
  img?: CMS_FileImageWithMeta
  top?: boolean
  imgRaw?: ImgAttrs
  itemProp?: string
  alt?: string
  title?: string
}

/**
 * A background image optimized for maximum reusability.
 * 1. The parent component must have position: absolute
 * 2. Place this component AS THE FIRST CHILD inside the parent component
 * 3. The other children on the parent component must have position: relative,
 *    otherwise they will be covered by the background image
 */
const BgImg = (props: BgImgProps): JSX.Element => {
  const imgProps = props.imgRaw ? props.imgRaw : UiMediaUtils.getImgPropsFromFileImageWithMeta(props.img || null);
  return (
    <img
      className={cn("absolute w-full h-full object-cover", props.top && "object-top")}
      itemProp={props.itemProp}
      {...imgProps}
      alt={imgProps.alt ?? props.alt}
      title={imgProps.title ??props.title}
    />
  );
};

export default BgImg;
