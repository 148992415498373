import {inject, injectable} from "inversify";
import {RestClient} from "@/src/common/network/rest/rest_client";
import type {IEnvVars} from "@/src/core/app/domain/@types/IEnvVars";
import {TYPES} from "@/src/core/app/ioc/types";
import type {NetworkInterfaces} from "@front_web_mrmilu/network";
import {CMS_NodeResponse} from "@/src/core/app/domain/cms/@types/response/CMS_NodeResponse";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {CMS_RouterResponse} from "@/src/core/app/domain/cms/@types/response/CMS_RouterResponse";
import DataLoaderContext from "@/src/core/app/data/data-loader/context/DataLoaderContext";

export interface GetOfferListParams {
  langcode?: string
  pageSize?: number
  offset?: number
  sort?: string
  hotelId?: string
  destinationId?: string
  experienceId?: string
  offerGroupId?: string
  from?: string
  to?: string
}

// This service is only used ServerSide
@injectable()
export class CmsApiService {
  private readonly envVars: IEnvVars;
  private client: RestClient | undefined;
  private readonly cmsAssetUrl: string;

  constructor(@inject(TYPES.IEnvVars) envVars: IEnvVars) {
    this.envVars = envVars;
    this.cmsAssetUrl = envVars.cmsAssetUrl;
  }

  getClient(context: DataLoaderContext) {
    if (!this.client) {
      this.client = new RestClient(this.envVars.cmsApiUrl, {
        headers: {
          'X-API-Key': this.envVars.cmsApiKey,
          'X-Referer-Url': context.appInfo.absoluteUrl,
        },
        timeout: this.envVars.cmsApiTimeout,
      });
    }

    return this.client;
  }

  getResourceUrl = (
    context: DataLoaderContext,
    entityType: string,
    entityBundle: string,
    uuid: string | null = null
  ) => {
    const url = `/${context.appInfo.langcode}/jsonapi/${entityType}/${entityBundle}`;
    if (uuid === null) {
      return url;
    }

    return url + '/' + uuid;
  }

  async get(
    context: DataLoaderContext,
    url: string,
    {params}: NetworkInterfaces.GetOptions = {},
  ): Promise<CMS_RouterResponse> {
    const res = await this.getClient(context).get(url, { params });
    context.onResponse(res);
    return res.data as CMS_RouterResponse;
  }

  async getNode(
    context: DataLoaderContext,
    nodeType: string,
    uuid: string
  ) : Promise<CMS_NodeResponse> {
    const url = this.getResourceUrl(context, 'node', nodeType, uuid);
    const res = await this.getClient(context).get<CMS_NodeResponse>(url);
    context.onResponse(res);
    return res.data;
  }

  async getNodeList(
    context: DataLoaderContext,
    nodeType: string,
    pageSize?: number,
    langcode?: string,
    sort?: string
  ) : Promise<any> {
    const url = this.getResourceUrl(context, 'node', nodeType);
    let params : Record<string, string> = {};
    if (langcode) {
      params['filter[langcode]'] = langcode;
    }
    if (pageSize) {
      params['page[limit]'] = pageSize.toString();
    }
    if (sort) {
      params['sort'] = sort;
    }
    const res = await this.getClient(context).get(url, {
      params,
    });
    context.onResponse(res);
    return res.data;
  }

  async getParagraph(
    context: DataLoaderContext,
    paragraphType: string,
    uuid: string
  ) : Promise<any> {
    const url = this.getResourceUrl(context, 'paragraph', paragraphType, uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getTaxonomyTerm(
    context: DataLoaderContext,
    taxonomyTermType: string,
    uuid: string
  ) : Promise<any> {
    const url = this.getResourceUrl(context, 'taxonomy_term', taxonomyTermType, uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getTaxonomyTermList(
    context: DataLoaderContext,
    bundle: string,
    pageSize: number,
    offset: number,
    langcode: string
  ) : Promise<any> {
    const url = this.getResourceUrl(context, 'taxonomy_term', bundle);
    const res = await this.getClient(context).get(url, {
      params: {
        'filter[langcode]': langcode,
        'page[limit]': pageSize,
        'page[offset]': offset,
        'sort': 'name',
      }
    });
    context.onResponse(res);
    return res.data;
  }

  async getMedia(
    context: DataLoaderContext,
    mediaType: string,
    uuid: string
  ) : Promise<any> {
    const url = this.getResourceUrl(context, 'media', mediaType, uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getFile(
    context: DataLoaderContext,
    uuid: string
  ) : Promise<any> {
    const url = this.getResourceUrl(context, 'file', 'file', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getOffer(
    context: DataLoaderContext,
    uuid: string
  ) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_offer', 'elba_offer', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getOfferList(
    context: DataLoaderContext,
    params?: GetOfferListParams
  ) : Promise<any> {
    const {
      langcode = 'es',
      pageSize = 9,
      offset = 0,
      sort = 'weight',
      hotelId = 'none',
      destinationId = 'none',
      experienceId = 'none',
      offerGroupId = 'none',
      from = 'none',
      to = 'none',
    } = params || {};

    const queryParams : Record<string, any> = {
      'filter[langcode]': langcode,
      'filter[cpt_active]': 1,
      'page[limit]': pageSize,
      'page[offset]': offset,
      'sort': sort,
    };

    if (hotelId != 'none') {
      queryParams['filter[hotel_id.id]'] = hotelId;
    }

    if (destinationId != 'none') {
      queryParams['filter[hotel_id.field_destination.id]'] = destinationId;
    }

    if (experienceId != 'none') {
      queryParams['filter[field_experiences.id]'] = experienceId;
    }

    if (offerGroupId != 'none') {
      queryParams['filter[group_id.id]'] = offerGroupId;
    }

    if (from != 'none') {
      queryParams['filter[from_from][condition][path]'] = 'datetime_from';
      queryParams['filter[from_from][condition][operator]'] = '<=';
      queryParams['filter[from_from][condition][value]'] = from;
      queryParams['filter[from_to][condition][path]'] = 'datetime_to';
      queryParams['filter[from_to][condition][operator]'] = '>=';
      queryParams['filter[from_to][condition][value]'] = from;
    }

    if (to != 'none') {
      queryParams['filter[to_from][condition][path]'] = 'datetime_from';
      queryParams['filter[to_from][condition][operator]'] = '<=';
      queryParams['filter[to_from][condition][value]'] = to;
      queryParams['filter[to_to][condition][path]'] = 'datetime_to';
      queryParams['filter[to_to][condition][operator]'] = '>=';
      queryParams['filter[to_to][condition][value]'] = to;
    }

    const url = this.getResourceUrl(context, 'elba_offer', 'elba_offer');
    const res = await this.getClient(context).get(url, {
      params: queryParams
    });
    context.onResponse(res);
    return res.data;
  }

  async getOfferGroup(
    context: DataLoaderContext,
    uuid: string
  ) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_offer_group', 'elba_offer_group', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getOfferGroupList(
    context: DataLoaderContext,
    pageSize: number,
    langcode: string
  ) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_offer_group', 'elba_offer_group');
    const res = await this.getClient(context).get(url, {
      params: {
        // 'filter[langcode]': langcode,
        'filter[cpt_active]': 1,
        'page[limit]': pageSize,
        'sort': 'title',
      }
    });
    context.onResponse(res);
    return res.data;
  }

  async getHotel(
    context: DataLoaderContext,
    uuid: string
  ) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_hotel_hotel', 'elba_hotel_hotel', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getHotelList(context: DataLoaderContext) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_hotel_hotel', 'elba_hotel_hotel');
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getElbaFidelityList(context: DataLoaderContext) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_fidelity', 'elba_fidelity');
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getReservation(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_reservation', 'elba_reservation', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getReservationList(context: DataLoaderContext) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_reservation', 'elba_reservation');
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getHotelRoom(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_hotel_room', 'elba_hotel_room', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getHotelRoomList(context: DataLoaderContext, params?: Record<string, any>) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_hotel_room', 'elba_hotel_room');
    const res = await this.getClient(context).get(url, {params});
    context.onResponse(res);
    return res.data;
  }

  async getRegion(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_region', 'elba_region', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getRegionList(context: DataLoaderContext) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_region', 'elba_region');
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getRateConfirmationPopup(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_booking_rate_confirm_popup', 'elba_booking_rate_confirm_popup', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getRateConfirmationPopupList(context: DataLoaderContext) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_booking_rate_confirm_popup', 'elba_booking_rate_confirm_popup');
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getCountry(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_country', 'elba_country', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getCountryList(context: DataLoaderContext, offset?: number) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_country', 'elba_country');
    const res = await this.getClient(context).get(url, {
      params: {
        'page[offset]': offset || 0
      }
    });
    context.onResponse(res);
    return res.data;
  }

  async getCurrency(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_currency', 'elba_currency', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getCurrencyList(context: DataLoaderContext) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_currency', 'elba_currency');
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getLanguage(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'language', 'language', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getLanguageList(context: DataLoaderContext) : Promise<any> {
    const url = this.getResourceUrl(context, 'language', 'language');
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getSocialNetwork(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_social_network', 'elba_social_network', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getSocialNetworkList(context: DataLoaderContext) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_social_network', 'elba_social_network');
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getMenu(context: DataLoaderContext, id: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'jsonapi_menu', id);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getNewsletterCategory(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_newsletter_category', 'elba_newsletter_category', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getNewsletterCategoryList(context: DataLoaderContext, offset?: number) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_newsletter_category', 'elba_newsletter_category');
    const res = await this.getClient(context).get(url, {
      params: {
        'page[offset]': offset || 0
      }
    });
    context.onResponse(res);
    return res.data;
  }

  async getLounge(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'elba_lounge', 'elba_lounge', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getLastBlogPosts(context: DataLoaderContext, pageSize: number, offset: number, langcode: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'node', 'blog');
    const res = await this.getClient(context).get(url, {
      params: {
        'filter[langcode]': langcode,
        'page[limit]': pageSize,
        'page[offset]': offset,
        'sort': '-created',
      }
    });
    context.onResponse(res);
    return res.data;
  }

  async getBlogPostsByCategory(context: DataLoaderContext, pageSize: number, offset: number, langcode: string, blogCategory: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'node', 'blog');
    const res = await this.getClient(context).get(url, {
      params: {
        'filter[langcode]': langcode,
        'page[limit]': pageSize,
        'page[offset]': offset,
        'filter[field_blog_category.id]': blogCategory,
        'sort': '-created',
      }
    });
    context.onResponse(res);
    return res.data;
  }

  async getBlogPostsPromoted(context: DataLoaderContext, pageSize: number, langcode: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'node', 'blog');
    const res = await this.getClient(context).get(url, {
      params: {
        'filter[langcode]': langcode,
        'filter[field_promoted]': '1',
        'page[limit]': pageSize,
        'sort': '-created',
      }
    });
    context.onResponse(res);
    return res.data;
  }

  async getBlogPostsPromotedByCategory(context: DataLoaderContext, pageSize: number, langcode: string, blogCategory: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'node', 'blog');
    const res = await this.getClient(context).get(url, {
      params: {
        'filter[langcode]': langcode,
        'filter[field_promoted]': '1',
        'filter[field_blog_category.id]': blogCategory,
        'page[limit]': pageSize,
        'sort': '-created',
      }
    });
    context.onResponse(res);
    return res.data;
  }

  async getBlogPostsPromotedByEntityQueue(context: DataLoaderContext, langcode: string) : Promise<any> {
    const entityBundle = `promoted_blog_posts_${langcode}`;
    const url = this.getResourceUrl(context, 'entity_subqueue', entityBundle);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  async getJobOffers(context: DataLoaderContext, pageSize: number, langcode: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'node', 'job_offer');
    const res = await this.getClient(context).get(url, {
      params: {
        'filter[langcode]': langcode,
        'page[limit]': pageSize,
        'sort': 'title',
      }
    });
    context.onResponse(res);
    return res.data;
  }

  async getSiteConfig(context: DataLoaderContext, uuid: string) : Promise<any> {
    const url = this.getResourceUrl(context, 'site-config', 'item', uuid);
    const res = await this.getClient(context).get(url);
    context.onResponse(res);
    return res.data;
  }

  buildAssetUrl(url: string) : string {
    return this.cmsAssetUrl + url;
  }
}
