import type { ReactNode } from "react";
import React from "react";

import cn from "classnames";

interface _Props {
  children: ReactNode
  paddingY?: string
  paddingX?: string
  fullHeight?: boolean
  className?: string
  backgroundColor?: string
}

const parseBackgroundColor = (color: string | undefined) => {
  if (typeof color === 'undefined') {
    return 'FFFFFF'
  } else if (color === 'none') {
    return null
  }
  return color;
};

const Section = (props: _Props): JSX.Element => {
  const {
    children,
    paddingY = "py-5",
    paddingX = "px-5",
    fullHeight = false,
    className,
    backgroundColor
  } = props;

  const bgColor = parseBackgroundColor(backgroundColor);
  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      backgroundColor: bgColor ? `#${bgColor}` : undefined,
    },
  };

  return (
    <section
      className={cn(paddingX, paddingY, fullHeight && "h-full", className)}
      style={styles.container}
    >
      {children}
    </section>
  )
};

export default Section;
