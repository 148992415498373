import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import Anchor from "@/src/ui/components/generic/Anchor";
import UrlUtils from "@/src/core/app/utils/url";
import {useTranslation} from "next-i18next";
import {PropsWithChildren} from "react";

type _Props = PropsWithChildren<{
  langcode: string
}>

const _Header = (props: _Props) => (
  <header>
    <Section className='bg-gray-20' backgroundColor='none'>
      <MaxWidthWrapper>
        <div className="flex justify-between items-end">
          <Anchor href={UrlUtils.getHomepageUrl(props.langcode)}>
            <img
              className="h-19 w-auto"
              src="/assets/images/generic/elba-logo.svg"
            />
          </Anchor>
        </div>
      </MaxWidthWrapper>
    </Section>
  </header>
);

const _Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-brand-2 pt-20 px-5 text-white text-xs">
      <MaxWidthWrapper>
        <aside className="py-10 text-center">
          {t('global.copyright.message')}
        </aside>
      </MaxWidthWrapper>
    </footer>
  );
}

const LayoutStatic = (props: _Props) => (
  <>
    <_Header langcode={props.langcode}/>
    <main>
      {props.children}
    </main>
    <_Footer />
  </>
);

export default LayoutStatic;
