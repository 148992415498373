import {NetworkResponse, NetworkResponseError, NetworkResponseErrorType} from "@/src/common/network/NetworkResponse";
import {AxiosError, AxiosResponse} from "axios";

export type NetworkErrorAnalyzer<E = any, AxiosErrorType = any> = (e: AxiosError<AxiosErrorType>) => NetworkResponseError<E>;

const NetworkUtils = {
  isNetworkResponseErrorOfType: (response: NetworkResponse, type: NetworkResponseErrorType) : boolean => {
     if (response.isOk) {
       return false;
     }
     if (response.error?.type === type) {
       return true;
     }
     return false;
  },
  getClientErrorResponse: <E = any>(e: AxiosError) : NetworkResponseError<E> => {
    return {
      type: NetworkResponseErrorType.CLIENT,
      data: {
        code: e.code as string,
        message: e.message,
      }
    }
  },
  getServerExpectedErrorResponse: <E = any>(e: AxiosError) : NetworkResponseError<E> => {
    return {
      type: NetworkResponseErrorType.SERVER_EXPECTED,
      data: (e.response as AxiosResponse).data
    }
  },
  getServerUnexpectedErrorResponse: <E = any>(e: AxiosError) : NetworkResponseError<E> => {
    return {
      type: NetworkResponseErrorType.SERVER_UNEXPECTED,
      data: {
        statusCode: (e.response as AxiosResponse).status,
        message: e.message
      }
    }
  },

  NetworkErrorKeyGetter__Type: (networkError: NetworkResponseError) => {
    return networkError.type;
  },

  NetworkErrorKeyGetter__DataIsErrorMapKey: (networkError: NetworkResponseError) => {
    return networkError.data;
  },

  NetworkErrorKeyGetter__DrupalCustomAPI: (networkError: NetworkResponseError) => {
    return networkError.data['error'];
  },

  defaultErrorAnalyzerFn: <E = any>(e: AxiosError) :  NetworkResponseError<E> => {
    if (e.response === undefined) {
      return NetworkUtils.getClientErrorResponse(e);
    }

    if (e.response.status === 400) {
      return NetworkUtils.getServerExpectedErrorResponse(e);
    }

    return NetworkUtils.getServerUnexpectedErrorResponse(e);
  },
  run: async <T = any, E = any, AxiosErrorType = any>(
    func: () => Promise<T>,
    networkErrorAnalyzer: NetworkErrorAnalyzer<E, AxiosErrorType> | null = null
  ) : Promise<NetworkResponse<T, E>> => {
    try {
      const data = await func();
      return {
        isOk: true,
        data,
      }
    }
    catch (exc) {
      const e = exc as AxiosError<AxiosErrorType>;
      return {
        isOk: false,
        error: networkErrorAnalyzer ?
          networkErrorAnalyzer(e) :
          NetworkUtils.defaultErrorAnalyzerFn<E>(e)
      };
    }
  }
};

export default NetworkUtils;
