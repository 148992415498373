import {inject, injectable} from "inversify";
import {RestClient} from "@/src/common/network/rest/rest_client";
import type {IEnvVars} from "@/src/core/app/domain/@types/IEnvVars";
import {TYPES} from "@/src/core/app/ioc/types";
import NetworkUtils from "@/src/common/network/NetworkUtils";
import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {BookingCheckInput} from "@/src/core/app/domain/booking/@types/Input/BookingCheckInput";
import {T_ReservationManage} from "@/src/core/app/domain/models/reservation/T_ReservationManage";
import {CMS_AuthHeaders} from "@/src/core/app/domain/cms/@types/auth/CMS_AuthHeaders";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";

@injectable()
export class SsrService {
  private readonly client: RestClient;

  constructor(@inject(TYPES.IEnvVars) envVars: IEnvVars) {
    this.client = new RestClient(envVars.publicSsrUrl, {
      timeout: envVars.publicSsrTimeout,
    });
  }

  async bookingManage(input: BookingCheckInput, authHeaders?: CMS_AuthHeaders) : Promise<NetworkResponse<T_ReservationManage>> {
    const url = '/api/ssr/booking/manage'
    return NetworkUtils.run(async () => {
      const res = await this.client.post<
        any,
        BookingCheckInput
      >(url, {
        data: input,
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    })
  }
}
