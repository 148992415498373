import UrlUtils from "@/src/core/app/utils/url";

const NavigationUtils = {
  getHomePath: (langcode: string) : string => {
    return UrlUtils.buildAppUrlWithLangPrefix(langcode, '/');
  },

  goToHome: (langcode: string) => {
    window.location.href = NavigationUtils.getHomePath(langcode);
  },
};

export default NavigationUtils;
