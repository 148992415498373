import HeroBasic from "@/src/ui/components/desktop/HeroBasic";
import {useTranslation} from "next-i18next";

const HeroError = () => {
  const { t } = useTranslation();
  return (
    <HeroBasic
      imgRaw={{src: '/assets/images/desktop/hero-error.png'}}
      category='Error'
      title={t('HeroError._Index.title')}
    />
  );
}

export default HeroError;
