import React from "react";
import AnchorLogout from "@/src/ui/components/generic/AnchorLogout";
import UrlUtils from "@/src/core/app/utils/url";
import {AnchorProps} from "@/src/ui/components/generic/Anchor/types";

const Anchor = (props: AnchorProps) => {
  const href = props.path ? UrlUtils.getCmsLinkUrlFromPath(props.path) : props.href;

  if (!href) {
    return (
      <span className={props.className}>
        {props.children}
      </span>
    );
  }

  if (href === '/user/logout') {
    return (
      <AnchorLogout {...props} />
    );
  }

  return (
    <a {...props} href={UrlUtils.getCmsLinkUrl(href)}>
      {props.children}
    </a>
  )
};

export default Anchor;
