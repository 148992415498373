import {NextApiRequest} from "next";
import {CMS_AuthCookie} from "@/src/core/app/domain/cms/@types/auth/CMS_AuthCookie";
import {CMS_AuthHeaders} from "@/src/core/app/domain/cms/@types/auth/CMS_AuthHeaders";
import {CMS_AuthHeadersType} from "@/src/core/app/domain/cms/@types/auth/CMS_AuthHeadersType";
import UserUtils from "@/src/core/app/utils/user";

const CMS_AuthUtils = {
  getAuthHeaders: (req?: NextApiRequest) : CMS_AuthHeaders | undefined => {
    if (req) {
      return CMS_AuthUtils.getAuthHeadersSsr(req);
    }
    return CMS_AuthUtils.getAuthHeadersBrowser();
  },
  getAuthHeadersBrowser: () : CMS_AuthHeaders | undefined => {
    const userData = UserUtils.getUserDataFromCookie();

    if (!userData) {
      return undefined;
    }
    return {
      type: CMS_AuthHeadersType.BROWSER,
      csrfToken: userData.csrf_token,
    }
  },
  getAuthHeadersSsr: (req: NextApiRequest) : CMS_AuthHeaders | undefined => {
    const authCookie = CMS_AuthUtils.getAuthCookieSsr(req);
    const csrfToken = CMS_AuthUtils.getCsrfTokenSsr(req);
    if (!authCookie || !csrfToken) {
      return undefined;
    }

    return {
      type: CMS_AuthHeadersType.SSR,
      authCookie,
      csrfToken,
    }
  },
  getAuthCookieSsr: (req: NextApiRequest) : CMS_AuthCookie | undefined => {
    let authCookie = undefined;

    for (const key of Object.keys(req.cookies)) {
      if (key.startsWith('SSESS')) {
        authCookie = {
          name: key,
          value: req.cookies[key] as string,
        }
      }
    }

    return authCookie;
  },
  getCsrfTokenSsr: (req: NextApiRequest) : string | undefined => {
    const csrfToken = req.headers['x-csrf-token'];
    if (!csrfToken) {
      return undefined;
    }
    return csrfToken as string;
  },

  getRawHeaders: (authHeaders: CMS_AuthHeaders | undefined) : Record<string, string> => {
    let headers : Record<string, string> = {};
    if (!authHeaders) {
      return headers;
    }

    headers['X-CSRF-Token'] = authHeaders.csrfToken;

    if (authHeaders.type === CMS_AuthHeadersType.SSR) {
      const authCookie = authHeaders.authCookie as CMS_AuthCookie;
      headers['Cookie'] = `${authCookie.name}=${authCookie.value}`;
    }
    return headers;
  }
};

export default CMS_AuthUtils;
