export interface NetworkResponse<T = any, E = any> {
  isOk: boolean
  error?: NetworkResponseError<E>
  data?: T
}

export enum NetworkResponseErrorType {
  CLIENT = 'CLIENT',
  SERVER_EXPECTED = 'SERVER_EXPECTED',
  SERVER_UNEXPECTED = 'SERVER_UNEXPECTED',
}

interface NetworkResponseErrorDataServerUnexpected {
  statusCode: number
  message: string
}

interface NetworkResponseErrorDataClient {
  code: string
  message: string
}

interface NetworkResponseErrorServerUnexpected {
  type: NetworkResponseErrorType.SERVER_UNEXPECTED
  data: NetworkResponseErrorDataServerUnexpected
}

interface NetworkResponseErrorClient {
  type: NetworkResponseErrorType.CLIENT
  data: NetworkResponseErrorDataClient
}

interface NetworkResponseErrorServerExpected<E = any> {
  type: NetworkResponseErrorType.SERVER_EXPECTED
  data: E
}

export type NetworkResponseError<E = any> =
  NetworkResponseErrorClient |
  NetworkResponseErrorServerUnexpected |
  NetworkResponseErrorServerExpected<E>
;