import {inject, injectable} from "inversify";
import {RestClient} from "@/src/common/network/rest/rest_client";
import type {IEnvVars} from "@/src/core/app/domain/@types/IEnvVars";
import {TYPES} from "@/src/core/app/ioc/types";
import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import NetworkUtils from "@/src/common/network/NetworkUtils";
import {CurrencyRatesSuccessOutput} from "@/src/core/app/domain/currency/@types/Output/CurrencyRatesSuccessOutput";

@injectable()
export class CurrencyApiService {
  private readonly client: RestClient;

  constructor(@inject(TYPES.IEnvVars) envVars: IEnvVars) {
    this.client = new RestClient(envVars.publicCurrencyApiUrl, {
      timeout: envVars.publicCurrencyApiTimeout
    });
  }

  getUrl(path: string) {
    const langcode = 'en';
    return `/${langcode}${path}`;
  }

  async getRates() : Promise<NetworkResponse<CurrencyRatesSuccessOutput>> {
    const url = this.getUrl('/api/1.0/currency/rates');
    return NetworkUtils.run<CurrencyRatesSuccessOutput>(async () => {
      const res = await this.client.get<CurrencyRatesSuccessOutput>(url);
      return res.data;
    });
  }
}
