import type { ReactNode } from "react";

import BgImg from "../../generic/BgImg";
import MaxWidthWrapper from "../../generic/MaxWidthWrapper";
import Section from "../../generic/Section/Section";
import {CMS_FileImageWithMeta} from "@/src/core/app/domain/cms/@types/file/CMS_FileImageWithMeta";

export interface HeroBasicProps {
  img?: CMS_FileImageWithMeta
  imgRaw?: any
  category: string
  title: string
  subtitle?: string | ReactNode
}

const HeroBasic = ({
  img,
  imgRaw,
  category,
  title,
  subtitle,
}: HeroBasicProps): JSX.Element => (
  <div className="block relative">
    <BgImg img={img} imgRaw={imgRaw} />
    <Section paddingY="">
      <MaxWidthWrapper>
        <div className="relative h-83.5 space-y-5 flex flex-col justify-center">
          <h1 className="title-5-white">{category}</h1>
          <h2 className="title-1-display font-cognito">{title}</h2>
          {subtitle && <p className="text-big-white max-w-3xl">{subtitle}</p>}
        </div>
      </MaxWidthWrapper>
    </Section>
  </div>
);

export default HeroBasic;
