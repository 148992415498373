import BrowserUtils from "@/src/ui/utils/browser";
import {ModalState} from "@/src/ui/view_models/modal.state";
import { immer } from "zustand/middleware/immer";
import {createStore, useStore} from "zustand";

const HTML_MODAL_CLASS = 'has-modal';

export const useModalStore = createStore<ModalState>()(immer((set, get) => ({
  modalKey: null,
  modalData: null,
  toggleModal: (newModalKey, modalData = null) => set(state => {
    const newValue = get().modalKey === newModalKey ? null : newModalKey;
    state.modalKey = newValue;
    state.modalData = modalData;
    BrowserUtils.toggleClassBasedOnCondition('html', HTML_MODAL_CLASS, newValue !== null);
  }),
  showModal: (modalKey, modalData = null) => set(state => {
    state.modalKey = modalKey;
    state.modalData = modalData;
    BrowserUtils.addClass('html', HTML_MODAL_CLASS);
  }),
  closeModal: () => set(state => {
    state.modalKey = null;
    state.modalData = null;
    BrowserUtils.removeClass('html', HTML_MODAL_CLASS);
  })
})));

export function useModalProvider(): ModalState;
export function useModalProvider<T>(selector: (state: ModalState) => T, equals?: (a: T, b: T) => boolean): T;
export function useModalProvider(selector?: any, equals?: any) {
  return useStore(useModalStore, selector, equals);
}
