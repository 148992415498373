export const TYPES = {
  IEnvVars: Symbol("IEnvVars"),
  TagManagerService: Symbol("TagManagerService"),
  CmsApiService: Symbol("CmsApiService"),
  SsrService: Symbol("SsrService"),
  BookingApiService: Symbol("BookingApiService"),
  UserApiService: Symbol("UserApiService"),
  FormsApiService: Symbol("FormsApiService"),
  CurrencyApiService: Symbol("CurrencyApiService"),
};
