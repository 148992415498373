import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {locator} from "@/src/core/app/ioc";
import {UserApiService} from "@/src/core/app/data/services/user_api_service";
import {TYPES} from "@/src/core/app/ioc/types";
import UserUtils from "@/src/core/app/utils/user";
import SentryUtils from "@/src/common/utils/sentry";

const UserLogoutUtils = {
  logout: async () : Promise<NetworkResponse> => {
    const userData = UserUtils.getUserDataFromCookie();

    if (userData === null) {
      SentryUtils.captureMessage('UserLogoutUtils.logout empty cookie');
      throw new Error('UserLogoutUtils.logout empty cookie');
    }

    const api = locator.get<UserApiService>(TYPES.UserApiService);
    return await api.userLogout(userData.logout_token);
  },
};

export default UserLogoutUtils;
