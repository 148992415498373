enum ModalKey {
  LOADER = 'LOADER',
  NOTIFICATION = 'NOTIFICATION',
  RESERVATION_CANCEL = 'RESERVATION_CANCEL',
  HOTEL_ROOM = 'HOTEL_ROOM',
  MULTI_GALLERY = 'MULTI_GALLERY',
  LOUNGE = 'LOUNGE',
  OFFER_FORM_BY_HOTEL = 'OFFER_FORM_BY_HOTEL',
  OFFER_FORM_BY_DESTINATION = 'OFFER_FORM_BY_DESTINATION',
  OFFER_FORM_BY_OFFER_GROUP = 'OFFER_FORM_BY_OFFER_GROUP',
  OFFER_FORM_BY_EXPERIENCE = 'OFFER_FORM_BY_EXPERIENCE',
  OFFER_FORM_DATE_RANGE = 'OFFER_FORM_DATE_RANGE',
  OFFER_FORM_OCCUPATION = 'OFFER_FORM_OCCUPATION',
  CHECKOUT_RATE_POPUP = 'CHECKOUT_RATE_POPUP',
  CHECKOUT_SPECIAL_TAXES = 'CHECKOUT_SPECIAL_TAXES',
}

export default ModalKey;
