import {inject, injectable} from "inversify";
import {RestClient} from "@/src/common/network/rest/rest_client";
import type {IEnvVars} from "@/src/core/app/domain/@types/IEnvVars";
import {TYPES} from "@/src/core/app/ioc/types";
import {GetAvailAndRatesParams} from "@/src/core/app/domain/booking/@types/Params/GetAvailAndRatesParams";
import NetworkUtils from "@/src/common/network/NetworkUtils";
import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {MakeBookingInput} from "@/src/core/app/domain/booking/@types/Input/MakeBookingInput";
import {MakeBookingOutput} from "@/src/core/app/domain/booking/@types/Output/MakeBookingOutput";
import {GetBookingTokenIframeInput} from "@/src/core/app/domain/booking/@types/Input/GetBookingTokenIframeInput";
import {GetBookingTokenIframeOutput} from "@/src/core/app/domain/booking/@types/Output/GetBookingTokenIframeOutput";
import {BookingBapTokenInput} from "@/src/core/app/domain/booking/@types/Input/BookingBapTokenInput";
import {BookingBapTokenOutput} from "@/src/core/app/domain/booking/@types/Output/BookingBapTokenOutput";
import {BookingBapKoInput} from "@/src/core/app/domain/booking/@types/Input/BookingBapKoInput";
import {BookingBapKoOutput} from "@/src/core/app/domain/booking/@types/Output/BookingBapKoOutput";
import {BookingCheckInput} from "@/src/core/app/domain/booking/@types/Input/BookingCheckInput";
import {
  BookingCancellationPolicyInput
} from "@/src/core/app/domain/booking/@types/Input/BookingCancellationPolicyInput";
import {
  BookingCancellationPolicyOutput
} from "@/src/core/app/domain/booking/@types/Output/BookingCancellationPolicyOutput";
import {BookingCancelInput} from "@/src/core/app/domain/booking/@types/Input/BookingCancelInput";
import {BookingMyListOutput} from "@/src/core/app/domain/booking/@types/Output/BookingMyListOutput";
import {CMS_AuthHeaders} from "@/src/core/app/domain/cms/@types/auth/CMS_AuthHeaders";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import AppController from "@/src/ui/controllers/AppController";
import {
  HotelCalendarAvailAndRatesOutput
} from "@/src/core/app/domain/booking/@types/Output/HotelCalendarAvailAndRatesOutput";

@injectable()
export class BookingApiService {
  private readonly client: RestClient;

  constructor(@inject(TYPES.IEnvVars) envVars: IEnvVars) {
    this.client = new RestClient(envVars.publicBookingApiUrl, {
      timeout: envVars.publicBookingApiTimeout,
    });
  }

  getUrl(langcode: string, path: string) {
    return `/${langcode}${path}`;
  }

  async getAvailAndRates(
    langcode: string,
    params: GetAvailAndRatesParams
  ) : Promise<NetworkResponse> {
    const url = this.getUrl(langcode, '/api/1.0/booking/avail-and-rates');
    return NetworkUtils.run(async () => {
      const res = await this.client.get<any>(url, {params});
      return res.data;
    })
  }

  async getHotelCalendarAvailAndRates(
    langcode: string,
    params: GetAvailAndRatesParams
  ) : Promise<NetworkResponse<HotelCalendarAvailAndRatesOutput>> {
    const url = this.getUrl(langcode, '/api/1.0/booking/hotel-calendar-avail-and-rates');
    return NetworkUtils.run(async () => {
      const res = await this.client.get<any>(url, {params});
      return res.data;
    })
  }

  async makeBooking(
    langcode: string,
    input: MakeBookingInput,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse<MakeBookingOutput>> {
    const url = this.getUrl(langcode, '/api/1.0/booking');
    return NetworkUtils.run(async () => {
      const res = await this.client.post<MakeBookingOutput, MakeBookingInput>(url, {
        data: input,
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    })
  }

  async getBookingTokenIframe(
    langcode: string,
    input: GetBookingTokenIframeInput,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse<GetBookingTokenIframeOutput>> {
    const url = this.getUrl(langcode, '/api/1.0/booking/token/iframe');
    return NetworkUtils.run(async () => {
      const res = await this.client.post<
        GetBookingTokenIframeOutput,
        GetBookingTokenIframeInput
      >(url, {
        data: input,
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    })
  }

  async bookingBapToken(
    langcode: string,
    input: BookingBapTokenInput,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse<BookingBapTokenOutput>> {
    const url = this.getUrl(langcode, '/api/1.0/booking/bap/token');
    return NetworkUtils.run(async () => {
      const res = await this.client.post<
        BookingBapTokenOutput,
        BookingBapTokenInput
      >(url, {
        data: input,
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    })
  }

  async bookingBapKo(
    langcode: string,
    input: BookingBapKoInput,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse<BookingBapKoOutput>> {
    const url = this.getUrl(langcode, '/api/1.0/booking/bap/ko');
    return NetworkUtils.run(async () => {
      const res = await this.client.post<
        BookingBapKoOutput,
        BookingBapKoInput
      >(url, {
        data: input,
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    })
  }

  async bookingCheck(
    input: BookingCheckInput,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse> {
    const url = this.getUrl(input.langcode, '/api/1.0/booking/check');
    return NetworkUtils.run(async () => {
      const res = await this.client.post<
        any,
        BookingCheckInput
      >(url, {
        data: input,
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    })
  }

  async bookingCancellationPolicy(
    langcode: string,
    input: BookingCancellationPolicyInput,
    authHeaders?: CMS_AuthHeaders
  ) :
    Promise<NetworkResponse<BookingCancellationPolicyOutput>> {
    const url = this.getUrl(langcode, '/api/1.0/booking/cancellation-policy');
    return NetworkUtils.run(async () => {
      const res = await this.client.post<
        BookingCancellationPolicyOutput,
        BookingCancellationPolicyInput
      >(url, {
        data: input,
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    })
  }

  async bookingCancel(
    langcode: string,
    input: BookingCancelInput,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse> {
    const url = this.getUrl(langcode, '/api/1.0/booking/cancel');
    return NetworkUtils.run(async () => {
      const res = await this.client.post<
        any,
        BookingCancellationPolicyInput
      >(url, {
        data: input,
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    })
  }

  async bookingMyList(
    langcode: string,
    authHeaders?: CMS_AuthHeaders
  ) : Promise<NetworkResponse<BookingMyListOutput>> {
    const url = this.getUrl(langcode, '/api/1.0/booking/my');
    return NetworkUtils.run(async () => {
      const res = await this.client.post<
        any,
        any
      >(url, {
        data: {},
        headers: CMS_AuthUtils.getRawHeaders(authHeaders)
      });
      return res.data;
    })
  }
}
