import "reflect-metadata";
import { Container } from "inversify";
import type { IEnvVars } from "@/src/core/app/domain/@types/IEnvVars";
import { TYPES } from "./types";
import { EnvVars } from "@/src/core/app/domain/models/EnvVars";
import { decorateDep } from "./utils";
import { TagManagerService } from "@front_web_mrmilu/services";
import {CmsApiService} from "@/src/core/app/data/services/cms_api_service";
import {BookingApiService} from "@/src/core/app/data/services/booking_api_service";
import {UserApiService} from "@/src/core/app/data/services/user_api_service";
import {SsrService} from "@/src/core/app/data/services/ssr_service";
import {CurrencyApiService} from "@/src/core/app/data/services/currency_api_service";
import {FormsApiService} from "@/src/core/app/data/services/forms_api_service";

// Third party deps
decorateDep(TagManagerService);

const locator = new Container();
locator.bind<IEnvVars>(TYPES.IEnvVars).to(EnvVars);
locator.bind<TagManagerService>(TYPES.TagManagerService).to(TagManagerService).inSingletonScope();
locator.bind<CmsApiService>(TYPES.CmsApiService).to(CmsApiService).inSingletonScope();
locator.bind<SsrService>(TYPES.SsrService).to(SsrService).inSingletonScope();
locator.bind<BookingApiService>(TYPES.BookingApiService).to(BookingApiService).inSingletonScope();
locator.bind<UserApiService>(TYPES.UserApiService).to(UserApiService).inSingletonScope();
locator.bind<CurrencyApiService>(TYPES.CurrencyApiService).to(CurrencyApiService).inSingletonScope();
locator.bind<FormsApiService>(TYPES.FormsApiService).to(FormsApiService).inSingletonScope();

// Repositories
// bindDynamicModule<IocProvider<IDummyRepository>, IDummyRepository>(TYPES.IDummyRepository, () =>
//   import("../../dummy/data/repositories/dummy_repository").then((module) => module.DummyRepository)
// );

// Use cases
// bindDynamicModule<IocProvider<GetDummyUsersUseCase>, GetDummyUsersUseCase>(TYPES.GetDummyUsersUseCase, () =>
//   import("../../dummy/domain/use_cases/get_dummy_users_use_case").then((module) => module.GetDummyUsersUseCase)
// );
// bindDynamicModule<IocProvider<CreateDummyPostUseCase>, CreateDummyPostUseCase>(TYPES.CreteDummyPostUseCase, () =>
//   import("../../dummy/domain/use_cases/create_dummy_post_use_case").then((module) => module.CreateDummyPostUseCase)
// );
// bindDynamicModule<IocProvider<GetDummyPostsUseCase>, GetDummyPostsUseCase>(TYPES.GetDummyPostsUseCase, () =>
//   import("../../dummy/domain/use_cases/get_dummy_posts_use_case").then((module) => module.GetDummyPostsUseCase)
// );

export { locator };
