type EventCallback<T = Event> = (event: T) => void;

const BrowserUtils = {
  addClass: (selector: string, className: string) => {
    document.querySelector(selector)?.classList.add(className);
  },
  removeClass: (selector: string, className: string) => {
    document.querySelector(selector)?.classList.remove(className);
  },
  toggleClassBasedOnCondition: (selector: string, className: string, condition: boolean) => {
    if (condition) {
      BrowserUtils.addClass(selector, className);
    }
    else {
      BrowserUtils.removeClass(selector, className);
    }
  },
  isBrowser: () : boolean => {
    return typeof window !== 'undefined';
  },
  addEventListenerToWindow: <T = Event>(type: string, listener: EventCallback<T>) => {
    if (!BrowserUtils.isBrowser()) {
      return;
    }

    window.addEventListener(type, listener as any);
  },
  onAppInitiated: <T = Event>(listener: EventCallback<T>) => {
    BrowserUtils.addEventListenerToWindow<T>('app-initiated', listener);
  },
  goTo: (url: string | null) => {
    if (!url) {
      return;
    }
    window.location.href = url;
  },
  dispatchEvent: <T = Event>(event: T) => {
    window.dispatchEvent(event as any);
  },
  reload: () => {
    window.location.reload();
  },
  getBaseUrl: () => {
    return window.location.origin;
  },
  buildUrl: (path: string) : URL => {
    const baseUrl = BrowserUtils.getBaseUrl();
    return new URL(path, baseUrl);
  },
}

export default BrowserUtils;
